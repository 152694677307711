<template>
  <div :class="className">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label :for="id" v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">{{ tooltip }}</tooltip>
      </div>
    </div>

    <textarea
      :id="id"
      :rows="rows"
      v-model="content"
      @input="handleInput"
      class="w-full py-2 px-3 border-2 rounded bg-white outline-none focus:border-black"
      :class="error ? 'border-red-600 hover:border-red-700' : 'border-gray-300 hover:border-gray-400'"
      :placeholder="placeholder"
    ></textarea>
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>
  </div>
</template>

<script>
import randomstring from 'randomstring'

export default {
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    tooltip: {
      type: String
    },
    rows: {
      type: Number,
      default: 8
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>
