<template>
  <div>
    <h1 class="font-bold text-xl mb-6">
      Job Details - #{{ $route.params.orderId }}
    </h1>

    <loader v-if="order === null" />
    <div v-else>
      <div class="flex flex-wrap -mx-2">
        <div class="w-full lg:w-2/3 px-2">
          <div class="flex flex-wrap -mx-2">
            <div class="w-full lg:w-1/2 px-2">
              <h2 class="text-lg font-bold mb-3">Vendor Details</h2>
              <p v-for="(vendor, i) in order.vendors" :key="i" class="mb-6">
                {{ vendor.name }}<br />
                {{ vendor.phone }}<br />
                <a :href="`mailto:${vendor.email}`" class="hover:underline">{{
                  vendor.email
                }}</a>
              </p>

              <h2 class="text-lg font-bold mb-3">Property Details</h2>
              <p class="mb-6">
                {{ order.property.address }}<br />
                {{ order.property.bedrooms }}
                {{ order.property.bedrooms === 1 ? "bedroom" : "bedrooms" }} |
                {{ order.property.guide_price | gbp }}<br />
              </p>

              <h2 class="text-lg font-bold mb-3">Office Details</h2>
              <p class="mb-3">
                {{ order.office.name }}
              </p>
              <p class="mb-3">
                {{ order.office.address }}
              </p>
              <p class="mb-6">
                {{ order.office.phone }}<br />
                <a :href="`mailto:${order.office.email}`" class="hover:underline">{{
                  order.office.email
                }}</a>
              </p>
            </div>

            <div class="w-full lg:w-1/2 px-2">
              <h2 class="text-lg font-bold mb-3">Additional Info</h2>
              <p class="mb-6"><strong>Order Number:</strong> {{ order.id }}</p>

              <p class="italic mb-6">
                {{ order.property.move_reason }}
              </p>
              <p class="whitespace-pre-line mb-6">{{ order.notes }}</p>

              <div class="flex justify-end">
                <modal heading="Photos" size="lg">
                  <template v-slot:trigger>
                    <i class="fas fa-images"></i> Photos
                  </template>

                  <p v-if="order.photos.length === 0">Photos not yet available.</p>
                  <div v-else class="flex items-center -mx-2">
                    <i class="fas fa-chevron-left px-2 cursor-pointer" @click="galleryIndex = galleryIndex === 0 ? order.photos.length - 1 : galleryIndex - 1"></i>
                    <div class="flex-1 px-2">
                      <img :src="order.photos[galleryIndex]" class="max-w-full" />
                    </div>
                    <i class="fas fa-chevron-right px-2 cursor-pointer" @click="galleryIndex = galleryIndex === order.photos.length - 1 ? 0 : galleryIndex + 1"></i>
                  </div>
                </modal>
              </div>
            </div>
          </div>

          <hr class="border-t my-6" />

          <h2 class="text-lg font-bold mb-3">Notes and Contacts</h2>
          <loader v-if="internalNotes === null" />
          <div v-else>
            <p v-if="internalNotes.length === 0" class="mb-3">
              There are no internal notes on this order.
            </p>
            <div v-else class="border-b border-1 border-black mb-3">
              <div v-for="note in internalNotes" :key="note.id" class="border p-3 mb-3">
                <div class="flex -mx-2">
                  <div class="w-20 px-2">
                    <img v-if="note.user.small_photo_url" :src="note.user.small_photo_url" class="w-16 h-16 rounded-full object-cover" />
                    <div v-else>
                      <div class="w-16 h-16 rounded-full flex items-center justify-center bg-gray-100 text-gray-600 text-lg">
                        <i class="fas fa-user"></i>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 px-2">
                    <div class="flex -mx-2">
                      <div class="flex-1 px-2">
                        <strong>{{ note.user.first_name }} {{ note.user.surname }}</strong>
                        <span class="bg-blue-500 text-white rounded-full px-2 text-xs ml-2" v-if="[1,2].includes(note.user.type)">ADMIN</span>
                        <em class="block mb-3">{{ note.created_at | datetime }} </em>
                        <div v-if="note.show_office || note.show_admin" class="flex mb-3">
                          <small class="mr-1">Sent To: </small>
                          <small v-if="note.show_admin">support@fineandcountry.com</small>
                          <small v-if="note.show_office && note.show_admin" class="mr-1">,</small>
                          <small v-if="note.show_office">{{order.office.email}} </small>
                        </div>
                      </div>
                      <div class="px-2" v-if="note.user_id === authUser.id">
                        <i class="fas fa-trash-alt cursor-pointer hover:text-red-600" title="Delete Note" @click="deleteNote(note)"></i>
                      </div>
                    </div>
                    <p class="whitespace-pre-line">{{ note.body }}</p>
                  </div>
                </div>
              </div>
            </div>

            <h2 class="text-lg font-bold mb-3">Add New Note</h2>
            <select-input v-model="internalNoteForm.subject" :className="'w-1/3'" :options="subjectOptions" :error="errorBag.get('subject')" />
            <textarea-input v-model="internalNoteForm.body" :error="errorBag.get('body')" :rows="4" class="mt-6" />
            <p class="mb-2">* ‘Internal Note’ unless tick boxes are selected below</p>
            <div class="flex">
              <checkbox-input :label="'Share note with Agency Office'" v-model="internalNoteForm.show_office" :error="errorBag.get('show_office')"/>
            </div>
            <div class="flex justify-end mt-2">
              <primary-btn :loading="savingInternalNote" @click="saveNote">
                Save / Submit Note
              </primary-btn>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/3 px-2">
          <label v-if="order.scheduled_at" class="px-6 py-3 rounded outline-none bg-black hover:bg-gray-900 cursor-pointer text-white flex justify-center mb-6">
            <input type="file" class="hidden" accept="text/rtf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.oasis.opendocument.text" @change="saveCopy($event)" />
            <loader v-if="savingCopy" />
            <span v-else><i class="fas fa-upload mr-2"></i> Upload Copy</span>
          </label>

          <a v-if="order.fulfillment.url" :href="order.fulfillment.url" class="px-6 py-3 rounded outline-none bg-black hover:bg-gray-900 cursor-pointer text-white block text-center mb-6">
            <i class="fas fa-download mr-2"></i> Download Copy
          </a>

          <div class="bg-gray-200 p-6 rounded mb-6" v-if="order.completed_at">
            <h2 class="text-lg font-bold mb-3">Job Completed</h2>
            <p class="mb-3">You completed this job at:</p>
            <p>{{ order.completed_at | datetime }}</p>
          </div>

          <div
            class="bg-gray-200 p-6 rounded mb-6"
            v-else-if="order.scheduled_at"
          >
            <h2 class="text-lg font-bold mb-3">Appointment</h2>
            <p class="mb-3">Your scheduled appointment time is:</p>
            <p>{{ order.scheduled_at | date }} {{ order.scheduled_at | day}} - {{ order.scheduled_at | time }}
            </p>


            <div class="flex justify-end mt-6">
              <modal>
                <template v-slot:trigger> Cancel Appointment </template>
                <p class="mb-3">
                  Are you sure you want to cancel the appointment?
                </p>

                <div class="flex justify-end">
                  <primary-btn
                    :loading="cancellingAppointment"
                    @click="cancelAppointment"
                  >
                    Cancel Appointment
                  </primary-btn>
                </div>
              </modal>
            </div>
          </div>

          <div class="bg-gray-200 p-6 rounded mb-6" v-else>
            <h2 class="text-lg font-bold mb-3">Book an Appointment</h2>
            <date-picker
              v-model="scheduleForm.scheduled_at"
              label="Select a Timeslot"
              :error="errorBag.get('scheduled_at')"
              placeholder="Please Select"
              :minDate="minStartDate"
              time
            />

            <div class="flex justify-end">
              <primary-btn
                :loading="bookingAppointment"
                @click="bookAppointment"
              >
                Book Appointment
              </primary-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/http";
import ErrorBag from "@/ErrorBag";
import Loader from "@/components/Loader";
import DatePicker from "../../components/DatePickerComponent";
import SelectInput from "../../components/SelectInputComponent";
import TextareaInput from "../../components/TextareaInputComponent";
import CheckboxInput from "../../components/CheckboxInputComponent";
import Modal from "../../components/ModalComponent";
import PrimaryBtn from "../../components/PrimaryBtn";
import moment from "moment";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Loader,
    DatePicker,
    PrimaryBtn,
    SelectInput,
    TextareaInput,
    CheckboxInput,
    Modal
  },
  data() {
    return {
      order: null,
      scheduleForm: {
        scheduled_at: null,
      },
      ticketForm: {
        subject: "None",
        body: "",
      },
      subjectOptions: [
        {name: 'None', id: 'None'},
        {name: 'Vendor not responding', id: 'Vendor not responding'},
        {name: 'Wrong email / Telephone number', id: 'Wrong email / Telephone number'},
        {name: 'Vendor does not wish to partake', id: 'Vendor does not wish to partake'}
      ],
      errorBag: new ErrorBag(),
      bookingAppointment: false,
      cancellingAppointment: false,
      submittingTicket: false,
      submittedTicket: false,
      savingCopy: false,
      internalNotes: null,
      internalNoteForm: {
        body: '',
        show_office: false,
        show_admin: false,
        subject:'None'
      },
      savingInternalNote: false,
      galleryIndex: 0
    };
  },
  computed: {
    ...mapState(['authUser']),
    minStartDate() {
      return moment().format("YYYY-MM-DD hh:mm:00");
    },
  },
  created() {
    this.getJob()
    this.getNotes()
  },
  methods: {
    ...mapMutations(["setSuccessMessage"]),
    getJob() {
      api
        .get(`/interviewer/jobs/${this.$route.params.orderId}`)
        .then((response) => {
          this.order = response.data;
        });
    },
    bookAppointment() {
      this.bookingAppointment = true;
      this.errorBag.clear();

      api
        .patch(
          `/interviewer/jobs/${this.$route.params.orderId}/schedule`,
          this.scheduleForm
        )
        .then(
          (response) => {
            this.order.scheduled_at = response.data.scheduled_at;
            this.bookingAppointment = false;

            this.setSuccessMessage("Appointment booked.");
          },
          (errors) => {
            this.errorBag.store(errors.response.data);
            this.bookingAppointment = false;
          }
        );
    },
    cancelAppointment() {
      this.cancellingAppointment = true;

      api
        .delete(
          `/interviewer/jobs/${this.$route.params.orderId}/cancel-schedule`
        )
        .then(() => {
          this.order.scheduled_at = null;
          this.cancellingAppointment = false;

          this.setSuccessMessage("Appointment cancelled.");
        });
    },
    submitTicket() {
      this.submittingTicket = true;

      api
        .post(
          `/interviewer/jobs/${this.$route.params.orderId}/ticket`,
          this.ticketForm
        )
        .then(
          (response) => {
            this.submittedTicket = response.data.ticket_id;

            this.setSuccessMessage(
              "Thank you. The studio have received your message."
            );
          },
          (errors) => {
            this.errorBag.store(errors.response.data);
            this.submittingTicket = false;
          }
        );
    },
    saveCopy(e) {
      if(this.savingCopy) {
        return;
      }

      this.savingCopy = true;
      this.errorBag.clear();

      let formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("file", e.target.files[0]);

      api
        .post(
          `/interviewer/jobs/${this.$route.params.orderId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(
          (response) => {
            this.savingCopy = false;
            this.order.fulfillment.url = response.data.url
            this.order.completed_at = response.data.completed_at
            this.order.status = response.data.status

            this.setSuccessMessage("Copy uploaded.");
          },
          (errors) => {
            this.errorBag.store(errors.response.data);
            this.savingCopy = false;
          }
        );
    },
    getNotes() {
      api.get(`/interviewer/jobs/${this.$route.params.orderId}/notes`).then((response) => {
        this.internalNotes = response.data
      })
    },
    saveNote() {
      this.errorBag.clear()
      this.savingInternalNote = true
      api.post(`/interviewer/jobs/${this.$route.params.orderId}/notes`, this.internalNoteForm).then((response) => {
        this.internalNotes.splice(0, 0, response.data)
        this.internalNoteForm.body = ''
        this.internalNoteForm.show_office = false
        this.internalNoteForm.show_admin = false

        this.savingInternalNote = false
      }, (errors) => {
        this.errorBag.store(errors.response.data)
        this.savingInternalNote = false
      })
    },
    deleteNote(note) {
      api.delete(`/interviewer/jobs/${this.$route.params.orderId}/notes/${note.id}`).then(() => {
        this.internalNotes.splice(this.internalNotes.indexOf(note), 1)
      })
    }
  },
};
</script>